<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form ref="cashTransactionForm" @submit.prevent="saveCashTransaction" class="custom-form">
                    <v-card :elevation="1" color="blue lighten-5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" dark :elevation="1" height="30px">
                                    <v-toolbar-title class="subtitle-2">Cash Transaction Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense justify="center">
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Tr ID</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        readonly
                                                        :value="$store.getters['cashTransaction/newCode']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Tr Type</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        item-text="account_name"
                                                        item-value="id"
                                                        :items="['Cash Receive', 'Cash Payment']"
                                                        v-model="cashTransaction.transaction_type"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Account</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        item-text="account_name"
                                                        item-value="id"
                                                        @focus="$store.dispatch('transactionAccount/getTransactionAccounts')"
                                                        :loading="$store.getters['transactionAccount/loading']"
                                                        :items="$store.getters['transactionAccount/transactionAccounts']"
                                                        v-model="selectedTransactionAccount"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1"> 
                                                <v-col cols="3" class="text-right pr-2">Tr Date</v-col>
                                                <v-col cols="9">
                                                    <v-menu
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="0"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="cashTransaction.transaction_date"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                autocomplete="off"
                                                            >
                                                                <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="cashTransaction.transaction_date"
                                                            @input="menu = false"
                                                            @change="getCashTransaction"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Description</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model.trim="cashTransaction.description"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Amount</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model.number="cashTransaction.amount"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row no-gutters>
                                                         <v-col cols="6" class="pr-1">
                                                            <v-btn @click="resetForm" height="30px" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn type="submit" :loading="waitingForSave" height="30px" dark block color="light-blue darken-2">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="transactionHeaders"
                    :loading="$store.getters['cashTransaction/loading']"
                    :items="$store.getters['cashTransaction/cashTransactions']"
                    :search="searchCashTransactions"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Cash Transaction List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Cash Transaction"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchCashTransactions"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-if="userType != 'user'" v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editCashTransaction(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="openDeleteDialog(item.id)" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="deleteDialog" @confirm="deleteCashTransaction"></delete-confirm>

    </v-container>
</template>

<script>
import DeleteConfirm from '@/components/Confirm'

export default {
    name: 'CashTransaction',
    components: {
        DeleteConfirm,
    },
    data: ()=> ({
        menu: false,
        transactionHeaders: [
            { text: 'Tr ID', value: 'code' },
            { text: 'Account Name', value: 'transaction_account.account_name' },
            { text: 'Date', value: 'transaction_date' },
            { text: 'Description', value: 'description' },
            { text: 'Received Amount', value: 'received_amount' },
            { text: 'Paid Amount', value: 'paid_amount' },
            { text: 'Saved By', value: 'added_by.name' },
            { text: 'Action', value: 'action' },
        ],
        searchCashTransactions: '',
        selectedTransactionAccount: null,
        cashTransaction: {
            transaction_type: '',
            amount: 0,
            transaction_date: new Date().toISOString().slice(0, 10),
            description: '',

        },
        waitingForSave: false,
        cashTransactionEditId: null,
        cashTransactionDeleteId: null,
        userType: ''
    }),
    async created() {
        await this.$store.dispatch('cashTransaction/getCashTransactionCode')
        await this.getCashTransaction();
        let userData = JSON.parse(localStorage.getItem('_userData'))
        this.userType = userData.user_type
    },
    methods: {
        async getCashTransaction() {
            let filter = {
                dateFrom: this.cashTransaction.transaction_date,
                dateTo: this.cashTransaction.transaction_date,
            }
            await this.$store.dispatch('cashTransaction/getCashTransactions', filter)
        },
        async saveCashTransaction() {
            if (!this.$refs.cashTransactionForm.validate()) return
            this.waitingForSave = true
            this.cashTransaction.transaction_account_id = this.selectedTransactionAccount.id
            if (this.cashTransactionEditId == null) {
                let res = await this.$store.dispatch('cashTransaction/saveCashTransaction', {
                    url: 'add-cash-transaction',
                    data: this.cashTransaction
                })
                if (res) {
                    this.resetForm()
                    swal({
                        title: "Do you want to show invoice?",
                        icon: "success",
                        buttons: true
                    }).then(response => {
                        if (response) {
                        if (response) this.$router.push(`/account/transaction-invoice/${res.transId}`);
                        }
                    });
                }
            } else {
                let res = await this.$store.dispatch('cashTransaction/saveCashTransaction', {
                    url: 'update-cash-transaction',
                    data: {...this.cashTransaction, id: this.cashTransactionEditId}
                })
                if (res) {
                    this.resetForm()
                    swal({
                        title: "Do you want to show invoice?",
                        icon: "success",
                        buttons: true
                    }).then(response => {
                        if (response) {
                        if (response) this.$router.push(`/account/transaction-invoice/${res.transId}`);
                        }
                    });
                }
            }
            this.waitingForSave = false
        },

        editCashTransaction(item) {
            this.cashTransactionEditId = item.id
            this.selectedTransactionAccount = item.transaction_account
            Object.keys(this.cashTransaction).map(k => this.cashTransaction[k] = item[k])
            if (item.transaction_type == 'Cash Receive') this.cashTransaction.amount = item.received_amount
            if (item.transaction_type == 'Cash Payment') this.cashTransaction.amount = item.paid_amount
        },

        openDeleteDialog(id) {
            this.cashTransactionDeleteId = id
            this.$refs.deleteDialog.dialog = true
        },

        deleteCashTransaction() {
            this.$store.dispatch('cashTransaction/deleteCashTransaction', {id: this.cashTransactionDeleteId})
            this.$refs.deleteDialog.dialog = false
            this.cashTransactionDeleteId = null
        },
        
        resetForm() {
            this.selectedTransactionAccount = null
            this.cashTransaction.transaction_type = ''
            this.cashTransaction.amount = 0
            this.cashTransaction.transaction_date = new Date().toISOString().slice(0, 10)
            this.cashTransaction.description = ''
            this.cashTransactionEditId = null
            this.$refs.cashTransactionForm.resetValidation()
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
</style>